const linkResolver = (doc) => {
    if (doc.type === 'page') {
      return `/${doc.uid}/`
    }
    if (doc.type === 'post') {
      return `/patient-resources/${doc.uid}/`
    }
    if (doc.type === 'service') {
      if (doc.tags.length === 0) {
        return `/services/${doc.uid}/`
      } else {
        return `/services/${doc.tags}/${doc.uid}/`
      }
    }
    return '/'
  }
  
  module.exports = linkResolver