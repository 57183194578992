import React from 'react'
import { ContentSection } from "./styles"


const FullWidthImage = ({ slice }) => (
  <ContentSection className="full-width-image">
    <img
      src={slice.primary.full_width_image.url}
      alt={slice.primary.full_width_image.alt}
    />
  </ContentSection>
)

export default FullWidthImage