import React from 'react'
import { RichText } from 'prismic-reactjs'
import linkResolver from './../../utils/linkResolver'
import { 
  TextContent
 } from '../../styles/utility'
import Section from '../Section'

const Text = ({ slice }) => {
  let columnItems = slice.items || [];
  let columnNumber = slice.primary.columns;
  let columnNumberToText;

  columnNumberToText = (columnNumber) => {
    switch(columnNumber) {
      case '1':
        return 'one';
      case '2':
        return 'two';
      case '3':
        return 'three';
      default: 
        return columnNumber;
    }
  }
  
  return (
    <Section className={`content-section`}>
      <TextContent>
        <RichText
          render={slice.primary.content.raw}
          linkResolver={linkResolver}
        />
      </TextContent>
      {columnItems && <div className={`gridColumn gridColumn-${columnNumberToText(columnNumber)}`}>
      {columnItems && columnItems.map((column, index) => (
            <div key={index}>
              {column.column_image.url != null && <img src={column.column_image.url} />}
              {column.column_heading.raw != null && 
                <RichText
                  render={column.column_heading.raw}
                  linkResolver={linkResolver}
                />}
              {column.column_text.raw != null &&
                <RichText
                  render={column.column_text.raw}
                  linkResolver={linkResolver}
                />}
            </div>
          ))}
      </div>}
    </Section>
  )
}

export default Text