import React from 'react'
import Slider from "react-slick";
import { RichText } from 'prismic-reactjs'
import "../../styles/slick/slick.css"; 
import "../../styles/slick/slick-theme.css";
import Section from '../Section'
import { GalleryItem } from "./styles"
import { TextContent, PrimaryButton } from '../../styles/utility'


const ImageGallery = ({ slice }) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Section sectionTitle={RichText.asText(slice.primary.gallery_title.raw)}>
      <Slider {...settings}>
        {slice.items.map((galleryItem, index) => (
          <GalleryItem key={`gallery-item=${index}`}>
            <img src={galleryItem.image.url} alt={galleryItem.image.alt} />
            <TextContent>
              <RichText render={galleryItem.image_description.raw} />
            </TextContent>
            {galleryItem.link && galleryItem.link ? (
              <p className="gallery-link">
                <PrimaryButton href={galleryItem.link.url}>
                  {RichText.asText(galleryItem.link_label.raw)}
                </PrimaryButton>
              </p>
            ) : null}
          </GalleryItem>
        ))}
      </Slider>
    </Section>
  )
}

export default ImageGallery