import styled from 'styled-components'

export const ContentSection = styled.section`
    margin-top: 2rem;
    margin-bottom: 2rem;
    &.full-width-image {
        img {
            max-width: 100%;
            width: 100%;
        }
    }
`;

export const GalleryItem = styled.div`
    text-align: center;
    img {
        display: block;
        margin: 0 auto;
    }
`;