import React from 'react'
import { RichText } from 'prismic-reactjs'
import Section from '../Section'
import {
  PrimaryButton,
  Column,
  TwoColumnGrid,
} from '../../styles/utility'

const ImageHighlight = ({ slice }) => (
  <Section className="highlight content-section">
    <TwoColumnGrid>
      <Column>
        <RichText render={slice.primary.title.raw} />
        <RichText render={slice.primary.description.raw} />
        {slice.primary.link_label &&
          <p>
            <PrimaryButton href={slice.primary.link.url}>
              {RichText.asText(slice.primary.link_label.raw)}
            </PrimaryButton>
          </p>
        }
      </Column>
      <Column>
        <img
          src={slice.primary.featured_image.url}
          alt={slice.primary.featured_image.alt}
        />
      </Column>
    </TwoColumnGrid>
  </Section>
)

export default ImageHighlight
