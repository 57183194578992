import React from 'react'
import { RichText } from 'prismic-reactjs'
import Section from '../Section'
import {
  Quotation,
} from '../../styles/utility'

const Quote = ({ slice }) => (
  <Section className="quote">
    <Quotation>{RichText.asText(slice.primary.quote.raw)}</Quotation>
  </Section>
)

export default Quote